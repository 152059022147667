<template>
  <b-row>
    <b-col
      cols="12"
      class="text-center mb-2"
    >
      <template v-if="formType === '1'">
        <b-button
          v-if="!canvasImageStatus"
          variant="danger"
          class="mr-1"
          @click="submitPdfImage"
        >
          <FeatherIcon icon="FileTextIcon" />
          <span class="align-middle">PDF OLUŞTUR</span>
        </b-button>
        <b-button
          v-else-if="canvasImageStatus && !exportPdfFile.status"
          variant="danger"
          class="mr-1"
          disabled
        >
          <b-spinner small />
          Lütfen Bekleyiniz...
        </b-button>
        <b-button
          v-if="exportPdfFile.status"
          variant="success"
          class="mr-1"
          :href="downloadURL"
          target="_blank"
          @click="resetPdfData"
        >
          <FeatherIcon icon="DownloadIcon" />
          <span class="align-middle">PDF İNDİR</span>
        </b-button>
      </template>
      <template v-else>
        <b-button
          variant="success"
          class="mr-1"
          :href="downloadURL"
          target="_blank"
        >
          <FeatherIcon icon="DownloadIcon" />
          <span class="align-middle">PDF İNDİR</span>
        </b-button>
      </template>
      <b-button
        v-if="idServiceStatuses === 1"
        variant="warning"
        :to="'/services/edit/' + id"
      >
        <FeatherIcon icon="EditIcon" />
        Güncelle
      </b-button>
      <b-button
        v-if="idServiceStatuses === 3"
        v-b-modal.email-modal
        variant="success"
      >
        <FeatherIcon icon="SendIcon" />
        E-Posta Gönder
      </b-button>
    </b-col>
    <b-col
      v-if="idServiceStatuses === 2"
      cols="12"
    >
      <b-alert
        show
        variant="info"
      >
        <div class="alert-body text-center">
          <FeatherIcon
            icon="InfoIcon"
            size="24"
          />
          <p class="lead">
            Servis Formu Onay Bekliyor.
          </p>
        </div>
      </b-alert>
      <div
        v-if="userApprovalStatus"
        class="text-center mb-2"
      >
        <b-button
          variant="success"
          @click="approve"
        >
          <FeatherIcon icon="CheckIcon" />
          Onayla
        </b-button>
        <b-button
          variant="danger"
          class="ml-1"
          @click="denied"
        >
          <FeatherIcon icon="XIcon" />
          Reddet
        </b-button>
        <b-button
          :variant="(revised)? 'warning' : 'info'"
          class="ml-1"
          @click="revised = !revised"
        >
          <FeatherIcon icon="InfoIcon" />
          Revize için Gönder
        </b-button>
        <b-card
          v-if="revised"
          class="mt-1 text-center"
        >
          <b-form-group
            label="Revize Notu"
            label-for="revised_note"
          >
            <b-form-textarea
              id="revised_note"
              v-model="revised_note"
              placeholder="Revize Notu"
            />
          </b-form-group>
          <b-button
            variant="primary"
            @click="sendRevised"
          >
            Gönder
          </b-button>
        </b-card>
      </div>
    </b-col>
    <email-modal />
  </b-row>
</template>
<script>
import {
  BRow, BCol, BButton, BAlert, BFormTextarea, BFormGroup, BCard, BSpinner,
} from 'bootstrap-vue'
import EmailModal from '@/views/Services/Components/EmailModal.vue'

export default {
  name: 'ActionButtons',
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BFormTextarea,
    BFormGroup,
    BCard,
    BSpinner,
    EmailModal,
  },
  props: {
    id: {
      type: Number,
      require: true,
    },
    idServiceStatuses: {
      type: Number,
      require: true,
    },
    submitPdfImage: {
      type: Function,
      require: true,
    },
    canvasImageStatus: {
      type: Boolean,
      require: true,
    },
    exportPdfFile: {
      type: Object,
      require: true,
    },
    downloadURL: {
      type: String,
      require: true,
    },
    resetPdfData: {
      type: Function,
      require: true,
    },
    formType: {
      // eslint-disable-next-line no-bitwise,vue/require-prop-type-constructor
      type: String | Number,
      require: true,
    },
  },
  data() {
    return {
      submitStatus: false,
      revised: false,
      revised_note: null,
    }
  },
  computed: {
    userApprovalStatus() {
      return JSON.parse(localStorage.getItem('userData')).approval
    },
    formData() {
      return this.$store.getters['services/getService']
    },
    saveStatus() {
      return this.$store.getters['services/getServiceSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.getData()
    },
  },
  methods: {
    getData() {
      if (this.submitStatus) {
        this.$store.dispatch('services/serviceView', this.$route.params.id)
        this.submitStatus = false
      }
    },
    approve() {
      this.submitStatus = true
      this.$store.dispatch('services/serviceApprove', {
        id: this.formData.id,
      })
    },
    denied() {
      this.submitStatus = true
      this.$store.dispatch('services/serviceDenied', {
        id: this.formData.id,
      })
    },
    sendRevised() {
      this.submitStatus = true
      this.$store.dispatch('services/serviceRevised', {
        id: this.formData.id,
        revised_note: this.revised_note,
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style scoped></style>
