<template>
  <div>
    <b-card title="Fotoğraflar">
      <b-row v-if="photos.length > 0">
        <b-col
          v-for="item in photos"
          :key="item.id"
          cols="12"
          sm="6"
          lg="3"
        >
          <div class="bg-light p-1 rounded-1 rounded mb-2">
            <b-img
              :src="item.filename"
              fluid-grow
              rounded
              class="cursor-pointer"
              @click="viewImage(item)"
            />
            <b-button
              class="mt-1"
              variant="primary"
              block
              size="sm"
              @click="viewImage(item)"
            >
              <FeatherIcon icon="EyeIcon" />
              Görüntüle
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-alert
        v-else
        show
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" />
          Servis formu için yüklenmiş fotoğraf bulunmamaktadır.
        </div>
      </b-alert>
    </b-card>
    <b-modal
      v-model="modalShow"
      centered
      hide-footer
      hide-header
      size="lg"
    >
      <b-img
        :src="modalImage.filename"
        fluid-grow
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BImg, BModal, BButton, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'PhotosCard',
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BModal,
    BButton,
    BAlert,
  },
  data() {
    return {
      modalImage: {},
      modalShow: false,
    }
  },
  computed: {
    photos() {
      return this.$store.getters['servicePhotos/dataList']
    },
  },
  methods: {
    viewImage(item) {
      this.modalShow = true
      this.modalImage = item
    },
  },
}
</script>
