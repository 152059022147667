<template>
  <div>
    <action-buttons
      :id="formData.id"
      :id-service-statuses="formData.id_service_statuses"
      :submit-pdf-image="submitPdfImage"
      :canvas-image-status="canvasImageStatus"
      :export-pdf-file="exportPdfFile"
      :download-u-r-l="downloadURL"
      :reset-pdf-data="resetPdfData"
      :form-type="formData.formType"
    />
    <b-row>
      <b-col
        v-if="formData.id_service_statuses === 1 && formData.revised_note"
        cols="12"
      >
        <b-alert
          show
          variant="info"
        >
          <div class="alert-body text-center">
            <FeatherIcon
              icon="MailIcon"
              size="24"
            />
            <p>{{ formData.revised_note }}</p>
          </div>
        </b-alert>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-title>Müşteri Kartı</b-card-title>
          <b-card-text><customer-card /></b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-title>Makine Kartı</b-card-title>
          <b-card-text>
            <machine-card />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-title>Servis Bakım</b-card-title>
          <b-card-sub-title class="mb-1">
            No: {{ formData.service_no }}
          </b-card-sub-title>
          <b-card-text>
            <service-card
              v-if="formData.formType === '1'"
              :service-data="formData"
              :service-history="history"
              :form-type="formData.formType"
            />
            <service-card2
              v-if="formData.formType === '2'"
              :service-data="formData"
              :form-type="formData.formType"
            />
          </b-card-text>
        </b-card>
      </b-col>
      <template v-if="formData.formType === '1'">
        <b-col v-if="chartData.concentration">
          <b-card no-body>
            <b-card-header>
              <b-card-title>Konsantrasyon</b-card-title>
            </b-card-header>
            <b-card-text
              id="concentration"
              class="bg-white"
            >
              <line-chart
                :data-labels="chartData.concentration.categories"
                :data-series="chartData.concentration.series"
                height="300"
              />
            </b-card-text>
          </b-card>
        </b-col>
        <b-col v-if="chartData.ph">
          <b-card no-body>
            <b-card-header>
              <b-card-title>pH</b-card-title>
            </b-card-header>
            <b-card-text id="ph">
              <line-chart
                :data-labels="chartData.ph.categories"
                :data-series="chartData.ph.series"
                height="300"
              />
            </b-card-text>
          </b-card>
        </b-col>
        <b-col v-if="chartData.conductivity">
          <b-card no-body>
            <b-card-header>
              <b-card-title>İletkenlik</b-card-title>
            </b-card-header>
            <b-card-text
              id="conductivity"
            >
              <line-chart
                :data-labels="chartData.conductivity.categories"
                :data-series="chartData.conductivity.series"
                height="300"
              />
            </b-card-text>
          </b-card>
        </b-col>
      </template>
      <b-col cols="12">
        <photos-card />
      </b-col>
      <b-col cols="12">
        <b-card class="text-center">
          <div class="text-muted">
            <FeatherIcon icon="CheckIcon" /> Servis formu {{ moment(formData.created).format('DD.MM.YYYY HH:mm') }} tarihinde {{ formData.username }} tarafından oluşturuldu.
          </div>
          <div
            v-if="formData.id_service_statuses === 3"
            class="text-muted"
          >
            <FeatherIcon icon="CheckIcon" /> Servis formu {{ moment(formData.approved).format('DD.MM.YYYY HH:mm') }} tarihinde {{ formData.user_approval }} tarafından onaylandı.
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardSubTitle, BCardText, BAlert,
} from 'bootstrap-vue'
import html2canvas from 'html2canvas'
import CustomerCard from '@/views/Services/Components/CustomerCard.vue'
import MachineCard from '@/views/Services/Components/MachineCard.vue'
import ServiceCard from '@/views/Services/Components/ServiceCard.vue'
import ServiceCard2 from '@/views/Services/Components/ServiceCard2.vue'
import LineChart from '@/views/Services/Components/LineChart.vue'
import ActionButtons from '@/views/Services/Components/ActionButtons.vue'
import PhotosCard from '@/views/Services/Components/PhotosCard.vue'

export default {
  name: 'View',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BAlert,
    CustomerCard,
    MachineCard,
    ServiceCard,
    ServiceCard2,
    LineChart,
    ActionButtons,
    PhotosCard,
  },
  data() {
    return {
      formData: {
        id: null,
        related_person: null,
        related_phone: null,
        notes: null,
        analysed: null,
        general_situation: null,
        refractometer: null,
        concentration: null,
        ph: null,
        conductivity: null,
        bacteria: null,
        mildew: null,
        revised_note: null,
        approved: null,
        id_material_types: null,
        id_used_oils: null,
        id_appearances: null,
        id_foreign_oil_ratios: null,
        id_service_statuses: null,
        id_machines: null,
        id_customers: null,
        id_customer_address: null,
      },
      canvasImages: {
        concentration: null,
        ph: null,
        conductivity: null,
      },
      canvasImageStatus: false,
    }
  },
  computed: {
    service() {
      return this.$store.getters['services/getService']
    },
    history() {
      return this.$store.getters['services/getServiceHistory']
    },
    chartData() {
      return this.$store.getters['services/getServiceChart']
    },
    exportPdfFile() {
      return this.$store.getters['services/getExportPdfFile']
    },
    downloadURL() {
      const urlParams = [
        `&id=${this.$route.params.id}`,
        `&dir=${this.exportPdfFile.folder}`,
      ].join('&')
      return `${this.$store.state.app.baseURL}/exports/pdf/services?token=${localStorage.getItem('downloadToken')}${urlParams}`
    },
  },
  watch: {
    service(data) {
      if (data.id) {
        this.setFormData(data)
        this.getCustomer(data.id_customers)
        this.getCustomerAddress(data.id_customer_address)
        this.getMachine(data.id_machines)
        this.getPhotos(data.id)
        this.getUsedProcesses()
        this.getUsedProducts()
      }
    },
  },
  created() {
    this.getService()
  },
  methods: {
    getService() {
      this.$store.dispatch('services/serviceView', this.$route.params.id)
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
    getCustomerAddress(id) {
      this.$store.dispatch('customerAddress/customerAddressView', id)
    },
    getMachine(id) {
      this.$store.dispatch('machines/machineView', id)
    },
    getPhotos(id) {
      this.$store.dispatch('servicePhotos/getDataList', { id })
    },
    getServiceHistory() {
      this.$store.dispatch('services/serviceHistory', {
        id: this.formData.id,
        id_machines: this.formData.id_machines,
        id_customers: this.formData.id_customers,
      })
    },
    setFormData(data) {
      const serviceData = data
      serviceData.id = Number(serviceData.id)
      serviceData.id_service_statuses = Number(serviceData.id_service_statuses)
      serviceData.general_situation = Number(serviceData.general_situation)
      this.formData = serviceData
      this.getServiceHistory()
    },
    resetPdfData() {
      this.exportPdfFile.status = null
      this.exportPdfFile.folder = null
      this.canvasImageStatus = false
      this.canvasImages = {
        concentration: null,
        ph: null,
        conductivity: null,
      }
    },
    getUsedProcesses() {
      this.$store.dispatch('usedProcesses/getDataList')
    },
    getUsedProducts() {
      this.$store.dispatch('usedProducts/getDataList')
    },
    async createPdfImages() {
      await this.createCanvas('concentration')
        .then(canvas => {
          this.canvasImages.concentration = canvas.toDataURL('image/jpg', 0.2)
        })
      await this.createCanvas('ph')
        .then(canvas => {
          this.canvasImages.ph = canvas.toDataURL('image/jpg', 0.2)
        })
      await this.createCanvas('conductivity')
        .then(canvas => {
          this.canvasImages.conductivity = canvas.toDataURL('image/jpg', 0.2)
        })
      return this.canvasImages
    },
    async submitPdfImage() {
      this.canvasImageStatus = true
      await this.createPdfImages()
        .then(images => {
          this.$store.dispatch('services/createPdf', images)
        })
    },
    async createCanvas(element) {
      return html2canvas(document.getElementById(element), {
        allowTaint: true, useCORS: true,
      })
    },
  },
}
</script>
